import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IBonus } from '../../type/bonus.type';

export type TGetBonusRecordsParams = CrudStore.TSearchParams<IBonus>;

export type TGetBonusRecordsResponse = CrudStore.TOutdatedSearchResponse<{
  bonusRecords: IBonus[];
}>;

export const getBonusRecordsApi = async (params?: TGetBonusRecordsParams): Promise<IBonus[]> => {
  const response = await API.get<TGetBonusRecordsResponse>('/account/bonusRecords/me', {
    params
  });

  return response.data._embedded?.bonusRecords ?? [];
};

import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IPromotionContent } from '../../type/promotion-content.type';

export type TGetPromotionContentsParams = CrudStore.TSearchParams<IPromotionContent> & {
  ids?: string[];
};

export type TGetPromotionContentsResponse = CrudStore.TSearchResponse<IPromotionContent>;

export const getPromotionContentsApi = async (params?: TGetPromotionContentsParams): Promise<TGetPromotionContentsResponse> => {
  const response = await API.get<IPromotionContent[]>('/account/a/promoContents', {
    params
  });

  return { data: response.data };
};

import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { IPromotion } from '../type/promotion.type';

export const fetchPromoViaVoucherCodeAction = action(
  'fetchPromoViaVoucherCodeAction',
  (voucherCode: string | undefined, options?: { callback?: TActionCallback<IPromotion> }) => {
    return {
      voucherCode,
      ...options
    };
  }
);

import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IBonus } from '../../type/bonus.type';

export type TGetBonusRecordsV2Params = CrudStore.TSearchParams<IBonus>;

export type TGetBonusRecordsV2Response = CrudStore.TSearchResponse<IBonus>;

export const getBonusRecordsV2Api = async (params?: TGetBonusRecordsV2Params): Promise<TGetBonusRecordsV2Response> => {
  const response = await API.get<IBonus[]>('/account/bonusRecords/me/v2', {
    params
  });

  return { data: response.data };
};

import { API } from '@totopkg/shared-util-api';
import { IBaseCrudItem } from '@totopkg/shared-util-crud';

import { IPromotionContent } from '../../type/promotion-content.type';

export const getPromotionContentApi = async (id: IBaseCrudItem['id']): Promise<IPromotionContent> => {
  const response = await API.get<IPromotionContent>(`/account/promoContents/${id}`);

  return response.data;
};

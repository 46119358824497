import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { EPromotionStatus, IPromotion } from '../../type/promotion.type';

export interface IPromotionParams extends IPromotion {
  statuses?: EPromotionStatus[];
}

export type TGetPromotionsParams = CrudStore.TSearchParams<IPromotionParams>;

export type TGetPromotionsResponse = CrudStore.TSearchResponse<IPromotion>;

export const getPromotionsApi = async (params?: TGetPromotionsParams): Promise<TGetPromotionsResponse> => {
  const response = await API.get<IPromotion[]>('/account/promoes', {
    params
  });
  return { data: response.data };
};

import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IPromotionAdmin } from '../../type/promotion-admin.type';

export type TGetPromotionsAdminParams = CrudStore.TSearchParams<IPromotionAdmin>;

export type TGetPromotionsAdminResponse = CrudStore.TSearchResponse<IPromotionAdmin>;

export type TGetPromotionsAdminApiResponse = CrudStore.TOutdatedSearchResponse<{
  promos: IPromotionAdmin[];
}>;

export const getPromotionsAdminApi = async (params?: TGetPromotionsAdminParams): Promise<TGetPromotionsAdminResponse> => {
  const response = await API.get<TGetPromotionsAdminApiResponse>('/account/a/promotions', {
    params
  });

  return { data: response.data._embedded?.promos };
};
